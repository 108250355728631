import React from 'react'
import { Alert } from 'react-bootstrap'
import './index.css'
import CypressReportRows from './CypressReportRows'
import MochaReportRows from './MochaReportRows'

class Reports extends React.Component {

    constructor(props) {
        super(props)
        this.state = { manifest: [], summary: {}, summaryRest: {}, summaryWs: {} }
    }

    millisecondsToTime(milli) {
        var seconds = Math.floor((milli / 1000) % 60);
        var minutes = Math.floor((milli / (60 * 1000)) % 60);

        return minutes + "m " + seconds + "s";
    }

    componentDidMount() {
        fetch(`${process.env.PUBLIC_URL}/reports-BFXQA-REST.json`)
            .then(res => res.json())
            .then(
                (json) => {
                    const options = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' };

                    let runDate = new Date(Date.parse(json.stats.start))
                    runDate = `${runDate.toLocaleDateString("en-EN", options)} - ${runDate.toLocaleTimeString()}`

                    json.stats.lastRun = runDate
                    json.stats.isPass = json.stats.failures == 0
                    json.stats.repoName = "bfxqa-rest"

                    this.setState({
                        isLoaded: true,
                        summaryRest: json.stats
                    });
                },
                // Note: it's important to handle errors here
                // instead of a catch() block so that we don't swallow
                // exceptions from actual bugs in components.
                (error) => {
                    this.setState({
                        isLoaded: true,
                        error
                    });
                }
            )

        fetch(`${process.env.PUBLIC_URL}/reports-BFXQA-WS.json`)
            .then(res => res.json())
            .then(
                (json) => {
                    const options = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' };

                    let runDate = new Date(Date.parse(json.stats.start))
                    runDate = `${runDate.toLocaleDateString("en-EN", options)} - ${runDate.toLocaleTimeString()}`

                    json.stats.lastRun = runDate
                    json.stats.isPass = json.stats.failures == 0
                    json.stats.repoName = "bfxqa-ws"

                    this.setState({
                        isLoaded: true,
                        summaryWs: json.stats
                    });
                },
                // Note: it's important to handle errors here
                // instead of a catch() block so that we don't swallow
                // exceptions from actual bugs in components.
                (error) => {
                    this.setState({
                        isLoaded: true,
                        error
                    });
                }
            )

        fetch(`${process.env.PUBLIC_URL}/reportsManifest.json`)
            .then(res => res.json())
            .then(
                (result) => {
                    let totalDuration = 0
                    let totalSuites = 0
                    let totalTests = 0
                    let totalPass = 0
                    let totalPending = 0
                    let totalFail = 0

                    result.forEach(x => {
                        totalDuration += x.details.totalDuration
                        totalSuites += x.details.totalSuites
                        totalTests += x.details.totalTests
                        totalPass += x.details.totalPassed
                        totalFail += x.details.totalFailed
                        totalPending += x.details.totalPending
                    })

                    const options = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' };

                    let runDate = new Date(Date.parse(result[0].details.startedTestsAt))
                    runDate = `${runDate.toLocaleDateString("en-EN", options)} - ${runDate.toLocaleTimeString()}`

                    this.setState({
                        isLoaded: true,
                        manifest: result,
                        summary: {
                            totalDuration: totalDuration,
                            totalSuites: totalSuites,
                            totalTests: totalTests,
                            totalPass: totalPass,
                            totalFail: totalFail,
                            totalPending: totalPending,
                            isPass: totalPass === totalTests - totalPending,
                            lastRun: runDate
                        }
                    });
                },
                // Note: it's important to handle errors here
                // instead of a catch() block so that we don't swallow
                // exceptions from actual bugs in components.
                (error) => {
                    this.setState({
                        isLoaded: true,
                        error
                    });
                }
            )
    }

    render() {
        return (
            <div className='App'>

                <h1>Latest Test Reports</h1>

                <h3>WS V2</h3>
                <Alert variant='primary'>
                    BFXQA-WS - Last executed on: {this.state.summaryWs.lastRun}
                </Alert>
                <Alert variant={this.state.summaryWs.isPass ? 'success' : 'danger'}>
                    STATUS: {this.state.summaryWs.isPass ? 'PASSED' : 'FAILED'}
                </Alert>
                <MochaReportRows state={this.state.summaryWs}></MochaReportRows>

                <h3>REST V2</h3>
                <Alert variant='primary'>
                    BFXQA-REST - Last executed on: {this.state.summaryRest.lastRun}
                </Alert>
                <Alert variant={this.state.summaryRest.isPass ? 'success' : 'danger'}>
                    STATUS: {this.state.summaryRest.isPass ? 'PASSED' : 'FAILED'}
                </Alert>
                <MochaReportRows state={this.state.summaryRest}></MochaReportRows>


                <h3>BFX - Cypress</h3>
                <Alert variant='primary'>
                    BFXQA - Last executed on: {this.state.summary.lastRun}
                </Alert>
                <Alert variant={this.state.summary.isPass ? 'success' : 'danger'}>
                    STATUS: {this.state.summary.isPass ? 'PASSED' : 'FAILED'}
                </Alert>
                <CypressReportRows state={this.state}></CypressReportRows>

            </div>
        )
    }
}

export default Reports