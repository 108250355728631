import React from 'react'
import Table from 'react-bootstrap/Table';
import Button from 'react-bootstrap/Button';
import NumberFormat from 'react-number-format';
import { mdiSortDescending, mdiSortAscending, mdiCloudDownloadOutline } from '@mdi/js';
import { Icon } from '@mdi/react'
import { Sparklines, SparklinesLine } from 'react-sparklines';
import './index.css'


function sortMetrics(metrics, direction, column) {

    let field = ""
    switch (column) {
        case "Pair":
            field = "pair"
            break
        case "Volume Self":
            field = "volumeSelf"
            break
        case "Volume BTC":
            field = "volumeBTC"
            break
        case "Volume USD":
            field = "volumeUSD"
            break
        case "Volume ETH":
            field = "volumeETH"
            break
    }


    console.log(`sorting: ${direction} : ${column}`)
    if (column == "Pair") {
        metrics.sort((a, b) => a[field].localeCompare(b[field]))
        if (direction == "desc")
            metrics.reverse()
    } else {

        metrics.sort((a, b) => a[field] - b[field])
        if (direction == "desc")
            metrics.reverse()
    }
}

class Volume extends React.Component {

    constructor(props) {
        super(props)
        this.state = { metrics: [], sortDirection: "desc", sortColumn: "Volume USD" }
    }

    sortColumn(column) {
        let direction = this.state.sortDirection
        let metrics = this.state.metrics
        let currentColumn = this.state.sortColumn
        // debugger
        if (currentColumn == column) {
            direction = direction == "desc" ? "asc" : "desc"
            sortMetrics(metrics, direction, column)
        } else {
            direction = "desc"
            sortMetrics(metrics, direction, column)
        }

        console.log(direction)
        this.setState(state => ({
            metrics: metrics,
            sortDirection: direction,
            sortColumn: column
        }))
    }

    componentDidMount() {
        fetch(`${process.env.PUBLIC_URL}/metrics.json`)
            .then(res => res.json())
            .then(
                (json) => {
                    sortMetrics(json, "desc", "Volume USD")
                    this.setState({
                        isLoaded: true,
                        metrics: json
                    });
                },
                // Note: it's important to handle errors here
                // instead of a catch() block so that we don't swallow
                // exceptions from actual bugs in components.
                (error) => {
                    this.setState({
                        isLoaded: true,
                        error
                    });
                }
            )
    }

    SortIcon(props) {
        if (props.column == props.state.sortColumn) {
            return <Icon path={props.state.sortDirection == "desc" ? mdiSortDescending : mdiSortAscending} size={1} color={"red"}></Icon>
        }
        return null
    }

    render() {
        return (
            <div className='App'>

                <h1>Volume Metrics</h1>
                <h4>Accurate self volume over 90 days & estimated conversions</h4>
                {/* <div style={{ padding: 5 }}><link><Button variant="success">Download CSV</Button></link></div> */}
                <div style={{ padding: 5 }}><a href={"metrics.csv"} target="_blank" rel="noopener noreferrer" download>
                    <Button variant="success">
                        Download CSV
                        <Icon style={{ marginLeft: 5 }} path={mdiCloudDownloadOutline} size={1} color={"black"}></Icon>
                    </Button>
                </a></div>
                <Table striped bordered hover>
                    <thead>
                        <tr>
                            <th onClick={() => this.sortColumn("Pair")}>
                                Pair
                                <this.SortIcon column={"Pair"} sortColumn={this.state.sortColumn} state={this.state} />
                            </th>
                            <th onClick={() => this.sortColumn("Volume Self")}>
                                Volume Self
                                <this.SortIcon column={"Volume Self"} sortColumn={this.state.sortColumn} state={this.state} />
                            </th>
                            <th onClick={() => this.sortColumn("Volume BTC")}>
                                Volume BTC
                               <this.SortIcon column={"Volume BTC"} sortColumn={this.state.sortColumn} state={this.state} />
                            </th>
                            <th onClick={() => this.sortColumn("Volume ETH")}>
                                Volume ETH
                                <this.SortIcon column={"Volume ETH"} sortColumn={this.state.sortColumn} state={this.state} />
                            </th>
                            <th onClick={() => this.sortColumn("Volume USD")}>
                                Volume USD
                                <this.SortIcon column={"Volume USD"} sortColumn={this.state.sortColumn} state={this.state} />
                            </th>
                            <th width="150">
                            </th>

                        </tr>

                    </thead>
                    <tbody>
                        {this.state.metrics.map(metric =>
                            <tr>
                                <td>
                                    {`${metric.pair}`}
                                </td>
                                <td>
                                    <NumberFormat value={metric.volumeSelf} thousandSeparator={true} displayType={'text'} suffix={` ${metric.leftSymbol}`} decimalScale={2} fixedDecimalScale={true} />
                                </td>
                                <td>
                                    <NumberFormat value={metric.volumeBTC} thousandSeparator={true} displayType={'text'} suffix={` BTC`} decimalScale={2} fixedDecimalScale={true} />
                                </td>
                                <td>
                                    <NumberFormat value={metric.volumeETH} thousandSeparator={true} displayType={'text'} suffix={` ETH`} decimalScale={2} fixedDecimalScale={true} />
                                </td>
                                <td>
                                    <NumberFormat value={metric.volumeUSD} thousandSeparator={true} displayType={'text'} suffix={` USD`} decimalScale={2} fixedDecimalScale={true} />
                                </td>
                                <td>
                                    <Sparklines width={200} data={metric.candles.map(c => c[5])}>
                                        <SparklinesLine color="blue" />
                                    </Sparklines>
                                </td>
                            </tr>
                        )}
                    </tbody>
                </Table>

                {/* <h3>WS V2</h3>
                <Alert variant='primary'>
                    BFXQA-WS - Last executed on: {this.state.summaryWs.lastRun}
                </Alert>
                <Alert variant={this.state.summaryWs.isPass ? 'success' : 'danger'}>
                    STATUS: {this.state.summaryWs.isPass ? 'PASSED' : 'FAILED'}
                </Alert>
                <MochaReportRows state={this.state.summaryWs}></MochaReportRows>

                <h3>REST V2</h3>
                <Alert variant='primary'>
                    BFXQA-REST - Last executed on: {this.state.summaryRest.lastRun}
                </Alert>
                <Alert variant={this.state.summaryRest.isPass ? 'success' : 'danger'}>
                    STATUS: {this.state.summaryRest.isPass ? 'PASSED' : 'FAILED'}
                </Alert>
                <MochaReportRows state={this.state.summaryRest}></MochaReportRows>


                <h3>BFX - Cypress</h3>
                <Alert variant='primary'>
                    BFXQA - Last executed on: {this.state.summary.lastRun}
                </Alert>
                <Alert variant={this.state.summary.isPass ? 'success' : 'danger'}>
                    STATUS: {this.state.summary.isPass ? 'PASSED' : 'FAILED'}
                </Alert>
                <CypressReportRows state={this.state}></CypressReportRows> */}

            </div >
        )
    }
}

export default Volume