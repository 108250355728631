import React from 'react'
import Table from 'react-bootstrap/Table';
import Button from 'react-bootstrap/Button';
import { mdiOpenInNew, mdiGithubCircle, } from '@mdi/js';
import { mdiBookOpenOutline } from '@mdi/js';
import { Icon } from '@mdi/react'
import './index.css'


function millisecondsToTime(milli) {
    var seconds = Math.floor((milli / 1000) % 60);
    var minutes = Math.floor((milli / (60 * 1000)) % 60);

    return minutes + "m " + seconds + "s";
}

class MochaReportRows extends React.Component {
    render() {
        return (
            <Table striped bordered hover>
                <thead>
                    <tr>
                        <th>
                            Status
              </th>
                        <th>
                            Duration
              </th>
                        <th>
                            Quantity
              </th>
                        <th>
                            # Passed
              </th>
                        <th>
                            # Failed
              </th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td >
                            <Button variant={this.props.state.failures == 0 ? "outline-success" : "outline-danger"} className="restrictWidth"
                                href={`${process.env.PUBLIC_URL}/reports-${this.props.state.repoName?.toUpperCase()}.html`} rel='nofollow' target='_blank'>
                                <span >{this.props.state.failures == 0 ? "Passed   " : "Failed   "}</span>
                                <Icon path={mdiBookOpenOutline} size={1} color="black"></Icon>
                                <Icon path={mdiOpenInNew} size={0.75} color="black"></Icon>
                            </Button>
                        </td>
                        <td>
                            <div className="mid">{`${millisecondsToTime(this.props.state.duration)}`}</div>
                        </td>
                        <td>
                            <Button variant="outline-secondary" className="restrictWidthLarge"
                                href={`https://github.com/garreth-sutton/${this.props.state.repoName?.toUpperCase()}`} rel='nofollow' target='_blank'>
                                {`${this.props.state.suites} Specs, ${this.props.state.tests} Tests   `}
                                <Icon path={mdiGithubCircle} size={1} color="black"></Icon>
                                <Icon path={mdiOpenInNew} size={0.75} color="black"></Icon>
                            </Button>
                        </td>
                        <td className='positive'>
                            <div className="mid">{`${this.props.state.passes}`}</div>
                        </td>
                        <td className='negative'>
                            <div className="mid">{`${this.props.state.failures}`}</div>
                        </td>
                    </tr>
                </tbody>
            </Table>
        )
    }
}

export default MochaReportRows