import React from 'react'
import Table from 'react-bootstrap/Table';
import Button from 'react-bootstrap/Button';
import { mdiOpenInNew, mdiPlay, mdiGithubCircle, mdiDotsVertical } from '@mdi/js';
import { mdiVideoOutline } from '@mdi/js';
import { mdiBookOpenOutline } from '@mdi/js';
import { Icon } from '@mdi/react'
import { OverlayTrigger, Popover } from 'react-bootstrap'
import './index.css'

const repoRoot = "https://github.com/bitfinexcom/bfxqa/tree/master/"

function millisecondsToTime(milli) {
    var seconds = Math.floor((milli / 1000) % 60);
    var minutes = Math.floor((milli / (60 * 1000)) % 60);

    return minutes + "m " + seconds + "s";
}

function getResultColour(details) {
    let totalTests = details.totalTests;
    let totalPassed = details.totalPassed
    let totalPending = details.totalPending

    if (totalTests == totalPending) {
        return "outline-secondary"
    } else if (totalTests == totalPassed) {
        return "outline-success"
    } else if (totalTests == totalPassed - totalPending) {
        return "outline-warning"
    } else {
        return "outline-danger"
    }
}

class CypressReportRows extends React.Component {



    render() {
        return (
            <Table striped bordered hover>
                <thead>
                    <tr>
                        <th>
                            Section
              </th>
                        <th>
                            Status
              </th>
                        <th>
                            Duration
              </th>
                        <th>
                            Quantity
              </th>
                        <th>
                            # Passed
              </th>
                        <th>
                            # Failed
              </th>
                        <th>
                            # Skipped
              </th>
                        <th>
                            Context
              </th>
                    </tr>
                </thead>
                <tbody>
                    {this.props.state.manifest.map((k) =>
                        <tr>
                            <td>
                                <Button variant={getResultColour(k.details)} className="restrictWidth" href={`${process.env.PUBLIC_URL}/reports/${k.name}/${k.report}`} rel='nofollow' target='_blank'>
                                    {`${k.name}   `}
                                    <Icon path={mdiBookOpenOutline} size={1} color="black"></Icon>
                                    <Icon path={mdiOpenInNew} size={0.75} color="black"></Icon>

                                </Button>
                            </td>
                            <td >

                                <OverlayTrigger trigger="focus" placement="right" overlay={
                                    <Popover>
                                        <Popover.Title as="h3">Videos</Popover.Title>
                                        <Popover.Content>

                                            {k.details.specResults.map(result =>
                                                <Button variant={getResultColour(k.details)} block target='_blank' rel='nofollow' href={`${process.env.PUBLIC_URL}/reports/${k.name}${result.video}`}>
                                                    {`Spec:  ${result.specPath.split("\\").pop()}  `}
                                                    <Icon path={mdiPlay} size={0.75} color="black"></Icon>
                                                    <Icon path={mdiOpenInNew} size={0.75} color="black"></Icon>
                                                </Button>
                                            )}

                                        </Popover.Content>
                                    </Popover>
                                }>
                                    <Button variant={getResultColour(k.details)} className="restrictWidth">
                                        <span >{k.details.totalTests === k.details.totalPassed ? "Passed   " : k.details.totalTests === k.details.totalPending ? "Skipped   " : "Failed   "}</span>
                                        <Icon path={mdiVideoOutline} size={1} color="black"></Icon>
                                        <Icon path={mdiDotsVertical} size={0.75} color="black"></Icon>
                                    </Button>
                                </OverlayTrigger>
                            </td>
                            <td>
                                <div className="mid">{`${millisecondsToTime(k.details.totalDuration)}`}</div>
                            </td>
                            <td>
                                <OverlayTrigger trigger="focus" placement="right" overlay={
                                    <Popover>
                                        <Popover.Title as="h3">Source</Popover.Title>
                                        <Popover.Content>

                                            {k.details.specResults.map(result =>
                                                <Button variant="outline-secondary" block target='_blank' rel='nofollow' href={`${repoRoot}${result.specPath}`}>
                                                    {`Spec:  ${result.specPath.split("\\").pop()}  `}
                                                    <Icon path={mdiOpenInNew} size={0.75} color="black"></Icon>
                                                </Button>
                                            )}

                                        </Popover.Content>
                                    </Popover>
                                }>
                                    <Button variant={getResultColour(k.details)} className="restrictWidth">
                                        {`${k.details.totalSuites} Specs, ${k.details.totalTests} Tests   `}
                                        <Icon path={mdiGithubCircle} size={1} color="black"></Icon>
                                        <Icon path={mdiDotsVertical} size={0.75} color="black"></Icon>
                                    </Button>
                                </OverlayTrigger>

                            </td>
                            <td className='positive'>
                                <div className="mid">{`${k.details.totalPassed}`}</div>
                            </td>
                            <td className='negative'>
                                <div className="mid">{`${k.details.totalFailed}`}</div>
                            </td>
                            <td className='positive text-warning'>
                                <div className="mid">{`${k.details.totalPending}`}</div>
                            </td>
                            <td>
                                <div className="mid">{`${k.details.osName} / ${k.details.browserName} @ ${k.details.browserVersion} / Cypress @ ${k.details.cypressVersion}`}</div>
                            </td>
                        </tr>
                    )}
                    {this.props.state.manifest.length > 0 && <tr>
                        <td></td>
                        <td></td>
                        <td className='summary'>{millisecondsToTime(this.props.state.summary.totalDuration)}</td>
                        <td className='summary'>{`${this.props.state.summary.totalSuites} Specs, ${this.props.state.summary.totalTests} Tests`}</td>
                        <td className='summary positive'>{this.props.state.summary.totalPass}</td>
                        <td className='summary negative'>{this.props.state.summary.totalFail}</td>
                        <td className='summary text-warning'>{this.props.state.summary.totalPending}</td>
                        <td></td>
                    </tr>}
                </tbody>
            </Table>
        )
    }
}

export default CypressReportRows