import React from 'react'
import './App.css'
import 'bootstrap/dist/css/bootstrap.min.css'
import Reports from './Reports'
import Error from './Error'
import Volume from './Volume'

import { BrowserRouter, Route, Switch } from 'react-router-dom';

class App extends React.Component {
  render() {
    return (
      <BrowserRouter>
        <div>
          <Switch>
            <Route path="/" component={Reports} exact></Route>
            <Route path="/reports" component={Reports} exact></Route>
            <Route path="/volume" component={Volume} exact></Route>
            <Route component={Error}></Route>
          </Switch>
        </div>
      </BrowserRouter>)
  }
}

export default App
