import React from 'react'

class Error extends React.Component {
    render() {
        return (
            <div className='text-center center'>
                <h2 >An error occured..</h2>
                <a href="/reports">Go back</a>
            </div>
        )
    }
}

export default Error